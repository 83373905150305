const ATC_API_ENDPOINT_SUFFIX = 'rrserver/api/rrPlatform/recsForPlacements';
const ATC_API_ENDPOINT = `https://recs.richrelevance.com/${ATC_API_ENDPOINT_SUFFIX}`;
const ATC_API_ENDPOINT_NON_PROD = `https://integration.richrelevance.com/${ATC_API_ENDPOINT_SUFFIX}`;

export const getATCTrackingUrl = ({
  userInformation,
  materialId,
}) => {
  const {
    rrApiUrl,
    rrApiKey,
    rrApiClientKey,
    sessionId,
    userInformation: {
      webLoginProfileId: userId,
    } = {},
  } = userInformation;

  const isIntegration = rrApiUrl?.includes?.('integration') || false;
  const url = isIntegration ? ATC_API_ENDPOINT_NON_PROD : ATC_API_ENDPOINT;
  
  const paramsData = {
    apiKey: rrApiKey,
    apiClientKey: rrApiClientKey,
    sessionId,
    userId,
    placements: 'add_to_cart_page',
    atcid: materialId,
  };

  // Remove empty parameters
  const filteredParamsData = Object.keys(paramsData).reduce((acc, key) => {
    if (paramsData[key]) {
      acc[key] = paramsData[key];
    }
    return acc;
  }, {});

  const params = new URLSearchParams(filteredParamsData);

  return `${url}?${params.toString()}`;
}