import axios, { POST } from './axios'

/**
 * @param parentId parent line item ID
 * @param item carrier activation item to be added to the cart
 * @param serviceType service type of the item to be added to the cart
 * @returns {Promise<*>}
 */
function addToCartCarrier(parentId, item, serviceType = 'carrier') {
  return axios({
    method: POST,
    url: `/insightweb/transaction/addtocart/${parentId}/${serviceType}`,
    data: item,
  });
}

function addToCartItems(item) {
  let items = Array.isArray(item) ? item : [ item ]
  return axios({
    method: POST,
    url: `/insightweb/transaction/addtocart`,
    data: items,
  });
}

function transformIfServiceTypeItem(items) {
  let item = Array.isArray(items) ? items?.[0] : items
  if(item?.serviceType) {
    return item
  }
  return null
}

export function serviceTypeFields({ parentId , serviceType }) {
  return {
    parentId,
    serviceType,
  }
}

export default function addToCart(items) {
  const serviceTypeItem = transformIfServiceTypeItem(items);
  if(serviceTypeItem) {
    const { parentId, serviceType, ...item  } = serviceTypeItem;
    return addToCartCarrier(parentId, item, serviceType);
  }
  return addToCartItems(items);

}
