export const trackCarrier = (trackItem, currentItem) => {
    // PDP, Search and Compare has serviceType as carrier when add a carrier to cart
    const isCarrier = currentItem?.serviceType === 'carrier'
    if(!isCarrier) return
    const categoryType = trackItem?.categoryType || 'cart'
    const { brand, insightPartId, materialID, name, price, quantity } = trackItem;
    delete trackItem.category
    delete trackItem.currency
    if(trackItem){
        Object.assign(trackItem, {
            insightPartId: insightPartId || materialID,
            productSku: insightPartId || materialID,
            name: name,
            serviceType: brand,
            price: price,
            quantity: quantity,
            brand: brand,
            categoryType: categoryType
        });
    }
}