import { addFieldsForHybridX } from "./hybridExperience";
import axios from "./axios";

export const _getProductDetails = async ({
    locale,
    materialId,
    salesOrg,
    userInformation,
    isLoggedIn,
    isIPSUser = false,
    contractName,
    contractId,
    includeSpecifications = true,
    includeVariants = true,
}) => {
    try {
        const {
            currencyCode,
            webLoginProfileId: userId,
            webGroup: { webGroupId } = {},
            account: { soldToId: soldTo } = {},
            UserType: userType,
            isCES,
            defConsortiaId: consortiaId,
            salesOrg: userSalesOrg,
        } = userInformation || {};
        
        const apiData = {
            locale,
            materialId: decodeURIComponent(materialId),
            salesOrg: salesOrg || userSalesOrg,
            includeSpecifications,
            includeVariants,
            currencyCode,
        };

        const ipsPostData = {
            ...apiData,
            soldTo: soldTo || null,
            userId: userId || null,
            webGroup: webGroupId || null,
            contractType: contractName,
            contractId,
            nonLoggedInIpsUser: !isLoggedIn,
            ipsUser: isIPSUser,
        };

        const postData = isLoggedIn
            ? {
                ...apiData,
                soldTo,
                userId,
                webGroup: webGroupId,
                ...(consortiaId && { consortiaId }),
            }
            : { ...apiData };

        const payload = isIPSUser ? ipsPostData : postData;
        
        await addFieldsForHybridX({ isLoggedIn, isCES }, payload, { userType });
        
        const { data } = await axios({
            method: "post",
            url: "/gapi/product-management/product",
            data: payload,
        });
        
        if (!data.product) console.warn("There is no matched product found");
        return data;
    } catch (err) {
        if (err.response?.data?.message) err = new Error(err.response.data.message);
        console.warn(`Failed to fetch product details`, err);
        throw err;
    }
};

function CachePromise(promise) {
  this.promise = promise.catch(() => this.error = true)
}

const CACHE_PRODUCT_DETAILS = {}

export const getProductDetails = ({
  locale,
  materialId,
  salesOrg,
  userInformation,
  isLoggedIn,
  isIPSUser = false,
  contractName,
  contractId,
  includeSpecifications = true,
  includeVariants = true,
}) => {
  const cache = CACHE_PRODUCT_DETAILS[materialId]
  if (cache && !cache.error) {
    return cache.promise
  }
  const productDetails = _getProductDetails({
    locale,
    materialId,
    salesOrg,
    userInformation,
    isLoggedIn,
    isIPSUser,
    contractName,
    contractId,
    includeSpecifications,
    includeVariants,
  })
  const newCache = new CachePromise(productDetails)
  CACHE_PRODUCT_DETAILS[materialId] = newCache
  return newCache.promise
}
