import axios from 'axios';
import getUserInformation from './getUserInformation';
import { getATCTrackingUrl } from '../../common/getATCTrackingUrl';

const sendATCTrackingForRR = async (payload) => {
  try {
    const { materialId } = payload || {};
    if (materialId) {
      const { data } = await getUserInformation();
      const atcUrl = getATCTrackingUrl({
        ...payload,
        userInformation: data,
      });
      if (atcUrl) {
        return axios.get(atcUrl);
      }
    }
  } catch (error) {
    console.log('Error while sending ATC tracking data: ', error);
  }
}

export default sendATCTrackingForRR;