import addToShoppingRequest from 'api/addToShoppingRequest'
import getUserInformation from 'api/getUserInformation'
import getFeatureFlagByName from "./api/us/getFeatureFlagByName";
import getAllFeatureFlags from "./api/us/getAllFeatureFlags";
import sendSignal from 'api/sendSignal'
import getSoftwareLicenseAgreements from './api/us/getSoftwareLicenseAgreements';
import getContracts from './api/us/getContracts';
import { isHybridXEnabled, addFieldsForHybridX } from "./common/hybridExperience";
import axios, { DELETE, GET, PUT, POST } from "./common/axios";
import {usePlacements} from "./common/usePlacements";
import { _getProductDetails, getProductDetails } from './common/getProductDetails';
import addToShoppingCart, { serviceTypeFields } from './common/addToCart';
import sendATCTrackingForRR from './api/us/sendATCTrackingForRR';
import { trackCarrier } from './common/trackCarrier';
export default {
    addToShoppingRequest,
    getUserInformation,
    sendSignal,
    getFeatureFlagByName,
    getAllFeatureFlags,
    getSoftwareLicenseAgreements,
    getContracts,
    _getProductDetails,
    getProductDetails,
    addFieldsForHybridX,
    isHybridXEnabled,
    usePlacements,
    addToShoppingCart,
    serviceTypeFields,
    sendATCTrackingForRR,
    trackCarrier,
    axios, DELETE, GET, PUT, POST
}

